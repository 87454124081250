import { createGlobalStyle } from 'styled-components';
import { useIsKeyboardMode } from './KeyboardModeProvider';
export const GlobalStyles = createGlobalStyle(({
  theme: _theme
}) => {
  const theme = _theme;
  const isKeyboardMode = useIsKeyboardMode();
  return {
    '*': {
      boxSizing: 'border-box'
    },
    '*::before': {
      boxSizing: 'border-box'
    },
    '*::after': {
      boxSizing: 'border-box'
    },
    /*
     * Removes default focus styles for non-input elements.
     * This is the replacement for the `hubspot-disable-focus-styles` CSS class in UIComponents.
     */
    '*:focus:not(input):not(textarea):not(.keep-default-focus-styles)': {
      outline: isKeyboardMode ? undefined : '0'
    },
    body: Object.assign({}, theme.typography['body-100'], {
      color: theme.color['text-primary-default']
    }),
    // Make the background unscrollable while FullScreen is open
    'html.FullScreenBackground, body.FullScreenBackground': {
      overflow: 'hidden'
    },
    // An element with this class is used to measure the scrollbar width
    '.FullScreen-scrollbar-measure': {
      position: 'absolute',
      top: '-9999px',
      width: '50px',
      height: '50px',
      overflow: 'scroll'
    },
    // Modifier to suppress navigation (used by the transitional EditorPage)
    '.space-sword--editor': {
      '#hs-nav-v4, #placeholder-vertical-nav, #trial-banner-block': {
        display: 'none'
      },
      '.page, .app': {
        marginInlineStart: theme.space['0'],
        inlineSize: 'auto'
      }
    }
  };
});